import React from 'react';
import {Container} from 'react-bootstrap';
import {Link} from 'react-router-dom';
import '../App.css';

const Esondageindex = () => {
	return (
		<div className="background-accueil">
			<Container className="">
				<br/><br/><br/><br/>
				<div className="div-accueil-titre">
					<h3>questionnaire</h3>
					<p>
						impact de la digitalisation sur la satisfaction des usagers-clients de la dgi
					</p>
				</div>
				<div className="div-accueil-description">
					<p>
						Votre satisfaction relative aux services fournis par la DGI est un indicateur important de la qualité de notre travail. Nous vous prions donc de bien vouloir remplir le questionnaire et de nous aider à améliorer nos services.<br/>Vous serez interrogé sur plusieurs aspects de la plateforme e-impots. Vos réponses seront uniquement utilisées par la DGI pour améliorer ses services et ne seront pas transmises à des tiers. Vos réponses seront traitées dans le respect de la confidentialité et resteront anonymes. 
					</p>
				</div>
				<div className="div-accueil-confidentialite">
					<span>
						Confidentialité :
					</span>
					<p>
						Les renseignements contenus dans ce questionnaire sont confidentiels. Ils sont couverts par le secret statistique et ne peuvent être publiés que sous forme anonyme conformement à la loi <i>n°2013-537 du 30 Juillet 2013</i> portant organisation du Système Statistique National.
					</p>
				</div>
				<Link className="btn-demarrer-accueil" to="/je-participe">Demarrer</Link><br/>
			</Container>
			<div className="div-accueil-footer">
				<Container>
					<br/>
					<br/>
					<p>Tél : (+225) 25 21 01 86 60</p>
				</Container>
			</div>
		</div>
	);
};

export default Esondageindex;
