import React, {useState, useEffect,useCallback, useRef} from 'react';
import {Container, Stack, Row, Col, Form, InputGroup, Button, Alert} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import axios from 'axios';

function Adminsondagelogin() {
    const navigate = useNavigate(null);
    const pseudoRef = useRef(null);
    const motdepasseRef = useRef(null);
    const [loginInfo, setLogininfo] = useState({
        pseudo: "adminsondage@dgi.gouv.ci",
        motdepasse: "",
    });
    const initialLogininfo = {
        pseudo: "",
        motdepasse: "",
    };
    const [loginError, setLoginerror] = useState("");
    const updateLoginInfo = useCallback((info) => {
        setLogininfo(info);
    }, []);
    const connecterAdministrateur = useCallback(async(e) => {
        e.preventDefault();
        try {
            const response = await axios.post('https://sondages.hermannlepatriote.com/login', loginInfo);
            setLoginerror("");
            if (response.data.erreur == "ok") {
                if (response.data.message == "Pseudo incorrect !")
                    pseudoRef.current.focus();
                if (response.data.message == "Mot de passe incorrect")
                    motdepasseRef.current.focus();
                return setLoginerror(response.data.message);
            } else {
                setLoginerror("");
                localStorage.setItem('adminId', true);
                navigate('/admin-sondage');
            }            
        } catch(error) {
            console.log('erreurlogin:',error);
        }
    }, [loginInfo, navigate]);
    function clearFields() {
        setLogininfo(initialLogininfo);
        pseudoRef.current.focus();
        setLoginerror("");
    };
    useEffect(() => {
        motdepasseRef.current.focus();
    }, []);
    return (
        <Container className="bg-connection-admin">
            <div className='bg-primary rounded text-white p-2 mb-2'>
                <Form onSubmit={connecterAdministrateur}>
                    <Row>
                        <Col>
                            <Stack gap={3}>
                                <InputGroup className="mb-1">
                                    <InputGroup.Text>Pseudo :</InputGroup.Text>
                                    <Form.Control ref={pseudoRef} as="input" value={loginInfo.pseudo} aria-label="Pseudo :" placeholder="email@dgi.gouv.ci" onChange={(e) => updateLoginInfo({...loginInfo, pseudo: e.target.value}) } />
                                </InputGroup>
                                <InputGroup className="mb-3">
                                    <InputGroup.Text>Mot de pass :</InputGroup.Text>
                                    <Form.Control ref={motdepasseRef}  type="password" value={loginInfo.motdepasse} placeholder="* * * * * *" onChange={(e) => updateLoginInfo({...loginInfo, motdepasse: e.target.value}) } />
                                </InputGroup>
                                {
                                    loginError ? (
                                        <Alert className="mb-0" variant="danger">{loginError}</Alert>
                                    ) : ( 
                                        <p className='hideitem'></p>
                                    )
                                }
                                <div className="flex">
                                    <Button variant="light" type="submit">
                                        Valider
                                    </Button>&nbsp;
                                    <Button variant="danger" onClick={clearFields}>
                                        Effacer
                                    </Button>
                                </div>                                
                            </Stack>
                        </Col>
                    </Row>
                </Form>
            </div>
		</Container>
    );
}

export default Adminsondagelogin;
