import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Esondageindex from './pages/Esondageindex';
import Esondageloading from './pages/Esondageloading';
import Esondageerror from './pages/Esondageerror';
import Esondageparticipation from './pages/Esondageparticipation';
import Adminsondageindex from './administration/Adminsondageindex';
import Adminsondagelogin from './administration/Adminsondagelogin';
import Adminsondagestatistiques from './administration/Adminsondagestatistiques';
import Adminsondagevisiteurs from './administration/Adminsondagevisiteurs';
import Adminsondagequestions from './administration/Adminsondagequestions';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

function Routingsystem() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Esondageindex />} />
        <Route path="/je-participe" element={<Esondageparticipation />} />
        <Route path="admin-sondage" element={<Adminsondageindex />} />
        <Route path="admin-statistiques" element={<Adminsondagestatistiques />} />
        <Route path="admin-visiteurs" element={<Adminsondagevisiteurs />} />
        <Route path="admin-questions" element={<Adminsondagequestions />} />
        <Route path="connection" element={<Adminsondagelogin />} />
        <Route path="*" element={<Esondageerror />} />
      </Routes>
    </Router>
  );
}

function App() {
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    getUserinfo();
    const temps = setTimeout(() => {
      setLoading(false);
    }, 3000);
    return () => clearTimeout(temps);
  }, []);
  const dateFormatted = (date) => {
		const jr = new Intl.DateTimeFormat('fr-FR', {weekday: 'short'}).format(date);
		const jour = String(date.getDate()).padStart(2, '0');
		const mois = date.getMonth() + 1;
		const annee = date.getFullYear();
		const heure = String(date.getHours()).padStart(2, '0');
		const min = String(date.getMinutes()).padStart(2, '0');
		const sec = String(date.getSeconds()).padStart(2, '0');
		return `${mois}/${jour}/${annee} à ${heure}:${min}`;
	};
  const moisCourrant = (date) => {
		const mois = date.getMonth() + 1;
		const annee = date.getFullYear();
		return `${mois}/${annee}`;
	};
  const getUserinfo = async() => {
    try {
      const respclient = await axios.get('https://ipapi.co/json');
      const dataClient = {ipaddr: respclient.data.ip, ville: respclient.data.city, region: respclient.data.region, pays: respclient.data.country_name+'/'+respclient.data.country_code_iso3, localisation: respclient.data.latitude+'/'+respclient.data.longitude, timezone: respclient.data.timezone, utcof: respclient.data.utc_offset, telcode: respclient.data.country_calling_code, datevisite: dateFormatted(new Date()), moisvisite:  moisCourrant(new Date())};
      const response = await axios.post('https://sondages.hermannlepatriote.com/visiteurs', dataClient);
      const resultat = response.json();
      if (resultat.data.erreur == "ok") {
        console.log(resultat.message);
      } else {
        if (resultat.erreur = "ok") {
          console.log(resultat.message);
        }
      }
    } catch(error) {
      console.error(error.message);
    }
  };
  return (
    <div>
      {
        loading ? <Esondageloading /> : <Routingsystem />
      }
    </div>
  );
}

export default App;
