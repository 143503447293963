import React, {useState, useEffect, useCallback, useRef} from 'react';
import axios from 'axios';
import {Container, Stack, Navbar, Row, Col, Form, InputGroup, Button, Alert} from 'react-bootstrap';
import {Link, useNavigate} from 'react-router-dom';
import '../App.css';
import '../tailwind-file.css';

const PageFooter = () => {
    const [scrollVisible, setScrollvisible] = useState(false);
    const toggleScrollView = () => {
        if (window.pageYOffset > 225) {
            setScrollvisible(true);
        } else {
            setScrollvisible(false);
        }
    };
    const naviguerTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    };
    useEffect(() => {
        window.addEventListener('scroll', toggleScrollView);
        return () => {
            window.removeEventListener('scroll', toggleScrollView);
        };
    }, []);
    return (
        <div className="questionnaire-footer py-4">
            <div className="footer-liens-utiles">
                <Row>
                    <Col>
                        <Link className="liens-utiles-a" to="http://www.gouv.ci/Main.php" target="_blank">
                            gouv.ci
                        </Link>
                    </Col>
                    <Col>
                        <Link className="liens-utiles-a" to="https://gouv-ci.koumoul.com/" target="_blank">
                            administration.gouv.ci
                        </Link>						
                    </Col>
                    <Col>
                        <Link className="liens-utiles-a" to="http://www.annuaire.gouv.ci/" target="_blank">
                            annuaire.gouv.ci
                        </Link>						
                    </Col>
                    <Col>
                        <Link className="liens-utiles-a" to="http://www.participationcitoyenne.gouv.ci/" target="_blank">
                            participationcitoyenne.gouv.ci
                        </Link>						
                    </Col>
                    <Col>
                        <Link className="liens-utiles-a" to="https://www.servicepublic.gouv.ci/" target="_blank">
                            servicepublic.gouv.ci
                        </Link>
                    </Col>
                    <Col>
                        <Link className="liens-utiles-a" to="https://data.gouv.ci/" target="_blank">
                            data.gouv.ci
                        </Link>
                    </Col>
                </Row>		
            </div>
            <div className="footer-contact hideitem">
                <div className="footer-contact-div">
                    <Row className="footer-contact-item">
                        <Col>
                            <p><b>Site :</b> www.dgi.gouv.ci</p>
                        </Col>
                    </Row>
                    <Row className="footer-contact-item">
                        <Col>
                            <p><b>E-mail :</b> infodgi@dgi.gouv.ci</p>
                        </Col>
                    </Row>
                    <Row className="footer-contact-item">
                        <Col>
                            <p><b>Tel :</b> +225 27 20 21 10 90 / 27 20 21 70 81 / 27 20 22 31 15</p>
                        </Col>
                    </Row>
                    <Row className="footer-contact-item">
                        <Col>
                            <p><b>Ligne verte :</b> 800 88 888</p>
                        </Col>
                    </Row>
                </div>
            </div>
            <div className="footer-copyright">
                <Row>
                    <p>©&nbsp;2025 Direction générale des Impôts de Côte d'Ivoire</p>
                </Row>
                <Row>
                    <p>Réalisé par la Direction des Systèmes d'Information</p>
                </Row>
                <Row>
                    <p>Tous droits reservés</p>
                </Row>
            </div>
            {
                scrollVisible && (
                    <Button variant="light" className="bg-scroll-top shadow border" onClick={() => naviguerTop()}><svg className="w-[33px] h-[33px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24"><path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="m5 15 7-7 7 7"/></svg>
                    </Button>
                )
            }
        </div>
    );

};

export default PageFooter;
